import PageLayout from '../components/PageLayout';
import Reviews from '../components/Reviews';

import getMenu from '../api/queries/menus/getMenu';
import Breadcrumb from '../components/Breadcrumb';
import createApolloClient from '../api';

const client = createApolloClient();

const Custom404 = ({ desktopHeaderData, aboutMenuData, toolsMenuData }) => {
  const pageName = {
    "pageBy": {
      "title": "404 Page",
      "slug": "404-page",
    }
  };
  const indexStatus = {
    "indexPage": false
  }

  return (
    <PageLayout menuData={desktopHeaderData} aboutMenuData={aboutMenuData} toolsMenuData={toolsMenuData} title="404" indexStatus={indexStatus}>
      <Breadcrumb data={pageName}/>
      <div className="custom404-error">
        <h3>Sorry, page under reconstruction, please try another page for now.</h3>
        <Reviews />
      </div>
    </PageLayout>
  );
};

export default Custom404;

export async function getStaticProps() {
  const desktopHeaderData = await client.query(getMenu('dGVybToyNQ=='));
  const aboutMenuData = await client.query(getMenu('dGVybTo1NQ=='));
  const toolsMenuData = await client.query(getMenu('dGVybTo1Ng=='));

  return { props: { desktopHeaderData, aboutMenuData, toolsMenuData }, revalidate: 10 };
}
