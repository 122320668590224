import slugToText from './../../utils/slugToText';

const Breadcrumb = ( {data} ) => {
  const {pageBy, articleBy, templateBy, teamBy} = data;
  const pageSlug = pageBy?.slug;
  const teamSlug = teamBy?.slug
  const articleTopicSlug = articleBy?.siteTopics?.nodes[0]?.slug;
  const articleSlug = articleBy?.slug;
  const templateTopicSlug = templateBy?.templateTopics?.nodes[0]?.slug;
  const templateSlug = templateBy?.slug;


  return (
    <ul className="breadcrumb">
      <li className="breadcrumb__item">
        <a href="/" className="breadcrumb__item__link">Home</a>
        <img src="/images/next.svg" width="12" height="12" alt="right-arrow"/>
      </li>
      {pageBy &&
        <li className="breadcrumb__item">{slugToText(pageSlug)}</li>
      }
      {teamBy &&
        <li className="breadcrumb__item">
          <a className="breadcrumb__item__link" href="/team">Team</a>
          <img src="/images/next.svg" width="12" height="12"/>
        </li>
      }
      {teamBy &&
        <li className="breadcrumb__item">{slugToText(teamSlug)}</li>
      }
      {articleBy &&
        <li className="breadcrumb__item">
          <a className="breadcrumb__item__link" href={`/${articleTopicSlug}`}>{slugToText(articleTopicSlug)}</a>
          <img src="/images/next.svg" width="12" height="12"/>
        </li>
      }
      {templateBy &&
        <li className="breadcrumb__item">
          <a className="breadcrumb__item__link" href={`/${templateTopicSlug}/templates`}>{slugToText(templateTopicSlug)}</a>
          <img src="/images/next.svg" width="12" height="12"/>
        </li>
      }
      {articleBy &&
        <li className="breadcrumb__item">{slugToText(articleSlug)}</li>
      }
      {templateBy &&
        <li className="breadcrumb__item">{slugToText(templateSlug)}</li>
      }
    </ul>
  );
};

export default Breadcrumb;
